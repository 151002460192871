@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';
@import '~react-image-lightbox/style.css';
@import '~animate.css/animate.min.css';
@import '../Css/table.scss';
@import '../Css/component.scss';

@font-face {
  font-family: Poppins;
  src: url('../Fonts/Poppins-Regular.otf');
}
@font-face {
  font-family: PoppinsSemibold;
  src: url('../Fonts/Poppins-SemiBold.otf');
}
@font-face {
  font-family: PoppinsBold;
  src: url('../Fonts/Poppins-Bold.otf');
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.headerTemplate {
  height: 48px !important;
  line-height: 46px;
  padding-left: 0px;
  padding-right: 0px;
  position: fixed;
  z-index: 1;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
}
.colHeaderLeft,
.colHeaderRight {
  line-height: 46px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: transparent !important;
}
.templateMenu {
  border: none;
}
.templateMenu li.ant-menu-item,
.templateMenu li.ant-menu-submenu div {
  padding-left: 21px !important;
  padding-right: 21px !important;
  margin-top: 0px;
  margin-bottom: 0px !important;
  padding-top: 4px;
}
.ant-menu-item {
  height: 48px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ant-menu-item-selected {
  color: white !important;
}
.imgESignPreview {
  min-height: 300px;
  min-width: 300px;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
.ant-menu-item:hover,
.ant-menu-submenu:hover {
  background-color: #26a69a !important;
  color: white !important;
  a {
    color: white !important;
  }
}
.templateMenu li.ant-menu-submenu ul li {
  padding-left: 45px !important;
  height: 48px !important;
}
.ant-menu-submenu-title {
  height: 48px !important;
  padding-top: 4px !important;
}
.capitalize {
  text-transform: capitalize;
}
.ant-layout-footer {
  padding: 25px 10px 10px 10px;
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #26a69a !important;
}
.ant-drawer-header {
  border-bottom: none !important;
}
.ant-layout-sider {
  overflow-y: auto;
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.25);
}
.ant-layout-sider::-webkit-scrollbar {
  width: 0 !important;
}
.ant-layout-header {
  background: white;
  height: 48px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #000002;
}
.colLoginLeft {
  height: 100vh;
  background-size: cover;
  padding: 30px;
  background-position: center;
}
.colLoginRight {
  padding: 50px;
  height: 100vh;
}
.divFormLogin {
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  width: 500px;
  margin: auto;
}
.ant-menu-inline-collapsed li {
  text-align: center;
}
.ant-form-item-children input,
.ant-form-item-children textarea,
.ant-select-selection {
  background-color: #f3f6f9;
  color: #464e5f !important;
  height: 50px;
  border: 1px solid #f3f6f9;
}
.ant-input-number {
  height: 100%;
  border: 1px solid #d9d9d9;
}
.ant-select-selection__rendered {
  padding-top: 8px;
}
.btnLogin {
  height: 50px;
}
.descriptionLogin {
  color: white;
  text-align: center;
  margin-bottom: 40px;
}
.titleLogin {
  color: white;
  font-size: 26px;
  margin-bottom: 0px;
  font-family: PoppinsSemibold, sans-serif;
  text-align: center;
  margin-top: 8vh;
  line-height: 21px;
}
.copyrightLogin {
  position: fixed;
  bottom: 5px;
}
.copyrightLogin,
.copyrightLogin a {
  color: white;
}
.inputSearchSchoolName {
  width: 250px;
}
.formSearchClassName {
  margin-right: 20px;
  .ant-form-item-label,
  .ant-form-item-explain {
    display: none;
  }
}
.layoutContainer {
  flex-flow: column;
}
.titleSider {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  text-transform: uppercase;
  width: 270px;
}
.divBackgroundSchoolList {
  height: 250px;
  text-align: center;
  padding-top: 50px;
}
.imgPreviewSchoolList {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.iconSchoolList {
  font-size: 150px;
  text-align: center;
  vertical-align: middle;
  line-height: 25vh;
}
.navbarBrand {
  position: relative;
  padding-left: 30px;
  width: 316px;
  display: inline-block;
}
.navbarNav {
  text-align: right;
  display: block;
}
.menuHeader {
  border-bottom: none;

  .ant-menu-item:after {
    border-bottom: none !important;
  }
}
.menuHeader .ant-menu-item:hover,
.menuHeader .ant-menu-item-selected {
  background-color: #26a69a !important;
  color: white !important;
  border-bottom: 2px solid white !important;
}
.divIconTrigger {
  width: 50px;
  padding-top: 5px;
  cursor: pointer;
}
.cardTrimester {
  .ant-card-head {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
  }
}
.navUser {
  text-align: right;
  list-style: none;
  padding-right: 30px;
}
.iconTriggerSider {
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: unset;
  margin-left: 14px;
}
.divIconTrigger:hover {
  background-color: #26a69a !important;
}
.cutomIcon {
  font-size: 18px !important;
  margin-right: 6px !important;
}
.colHeaderRight {
  text-align: right;
}
.iconProfileHeader {
  margin-left: 10px;
}
.profileNameHeader {
  color: white;
}
.footer {
  background: white;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
.copyrightFooter {
  font-size: 12px;
}
.containerContent {
  min-height: 90vh;
  margin-top: 48px;
  padding: 30px 50px 30px 50px;
  width: 100%;
}
.cardSchoolList {
  .ant-card-actions {
    li {
      margin: 0;
    }
  }
  .btnActionCardSchoolList {
    border: none;
    border-radius: 0;
    margin: 0px;
  }
}
.divBackgroundSchool {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-bottom: 30px;
}

.headerBottom {
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
  padding-left: 20px;
  padding-right: 20px;
}
.timelineLessonPlan {
  margin-top: 20px;
  .ant-collapse-content {
    background-color: #ffffff !important;
    border: 1px solid #f0f0f0;
  }
}
.timelineLessonPlanSuccess {
  .ant-collapse-header {
    background-color: #28b62c;
    color: #ffffff !important;
  }
}
.timelineLessonPlanWarning {
  .ant-collapse-header {
    background-color: #ff851b !important;
    color: #ffffff !important;
  }
}

.tagHeaderNow {
  padding: 8px;
  margin-right: 0px;
}
.tagHeaderToday {
  color: #b5b5c3;
}
.tagHeaderTodayDate {
  color: #26a69a;
}
.breadCrumbHeader {
  margin-top: 12px;
}
.btnSubmitCard {
  margin-bottom: 20px;
}
.containerButtonAction {
  display: block;
  text-align: center;
}
.btnActionDetail {
  margin: 5px;
}

.ant-btn-success {
  margin-right: 5px;
  margin-left: 5px;
}
.layoutContainerComponent {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.layoutContainerComponent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.__buttonCloseReactModal {
  position: absolute;
  font-size: 20px;
  box-shadow: none;
  top: 4px;
}
.ant-layout-header {
  background: white;
  height: 48px;
  line-height: 48px;
}

.imgPreviewUppy {
  height: 100px;
  margin: 20px 0px 20px 0px;
  border: #dee2e6 solid 1px;
}
.drawerTitle {
  font-size: 1.3rem;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 550;
}
.btnCloseDrawer {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 30px;
  width: 30px;
  padding-left: 8px;
  background-color: #26a69a;
  border-width: 0 1px 4px 1px;
  border-color: #1a746b !important;
}
.btnCloseDrawer:hover {
  background-color: #1a746b;
}
.iconCloseDrawer {
  font-size: 14px;
  margin-left: 0px;
  color: white;
}
.divHeaderProfile {
  cursor: pointer;
  display: inline-block;
  height: 85%;
  padding-left: 5px;
  padding-right: 5px;
}
.userProfileMenu:hover {
  background: #26a69a;
}
.avatarProfileDrawer {
  height: 95px;
  width: 95px;
  padding-top: 5px;
}
.avatarProfileDrawer i {
  font-size: 80px;
}
.profileName {
  font-family: PoppinsSemibold, sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
}
.profileRole,
.profileEmail {
  margin-bottom: 0px;
  color: #b5b5c3;
}
.btnLogout {
  margin-top: 15px;
}
.btnAddItemSelect {
  margin-top: 15px;
  background: #e1f0ff !important;
  color: #3699ff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.ReactModal__Content {
  .commonCardStyle {
    margin-bottom: 30px;
    .ant-card-body,
    .ant-card-head {
      padding: 0px;
    }
  }
}
.pHistoryCapitalPrice {
  font-weight: bolder;
}
.btnSuggestPrice {
  background: #26a69a !important;
  color: #fff;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}
.suggestedPriceHistory {
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 10px;
}
.suggestedLastUsed,
.suggestedLastUsedBy {
  margin-bottom: 0;
}
.divContainerOption {
  position: relative;
  cursor: pointer;
}
.divContainerIcon {
  background-color: #f3f6f9;
  width: 40px;
  height: 40px;
}
.divContainerOption:hover {
  padding: 5px;
  background: #26a69a;
}
.divContainerIcon .anticon {
  font-size: 24px;
  margin-top: 8px;
  margin-left: 8px;
  color: #1ac5bd;
}
.pDrawerOptionTitle {
  position: absolute;
  top: 4px;
  left: 50px;
  font-family: PoppinsBold, sans-serif;
}
.ant-menu-item-selected {
  a {
    color: white !important;
  }
}
.pDrawerOptionDesc {
  position: absolute;
  top: 26px;
  left: 50px;
  font-size: 12px;
}
.colBtnSearchPeriod {
  position: relative;
}
.btnSearchPeriod {
  position: absolute;
  bottom: 0px;
}
.inputRefund {
  background-color: #c9f7f5;
}
.tableProfitLoss {
  display: table;
  width: 100%;
}
.profitLossName {
  width: 60%;
}
.profitLossCredit {
  width: 20%;
}
.profitLossDebit {
  width: 20%;
}
.tableProfitLossTitle {
  border-bottom-style: double !important;
  padding-top: 30px;
}
.profitLossEnd {
  border-bottom-style: double !important;
}
.profitLossFirst td {
  padding-top: 30px;
}
.netProfit {
  font-family: PoppinsBold, sans-serif;
}
.rowDashboard {
  margin-bottom: 30px;
}
.iconDashboard {
  font-size: 70px;
}
.titleCardDashboard {
  margin-bottom: 0px;
  margin-left: 30px;
  font-size: 16px;
}
.valueCardDashboard {
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 0px;
  margin-left: 30px;
}
.selectDescTitle,
.selectDescDecription {
  margin-bottom: 0;
}
.selectDescTitle {
  font-family: PoppinsBold, sans-serif;
}
.ant-select-selection-selected-value .selectDescTitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}
.ant-select-selection-selected-value .selectDescDecription {
  display: none;
}
.imageNotFound {
  min-width: 300px;
  margin: auto;
  display: block;
  margin-top: 8vh;
}
.divNotFound {
  background: #20a8d8;
  width: 300px;
  margin: auto;
  text-align: center;
  font-size: 30px;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
}
.pNotFound {
  margin: auto;
  display: block;
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
}
.containerNotFound {
  height: 100vh;
  width: 100vw;
  display: flow-root;
}
.backToHome {
  display: block;
  margin: auto;
  margin-top: 30px;
  background: #20a8d8;
  border: #20a8d8;
}
.appNameLogin {
  text-transform: uppercase;
  font-size: 42px;
}
.rowTablePermission {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #fafbfb;
}
.rowTablePermissionHeader {
  background: #ecf0ef;
  font-weight: bolder;
}
.rowCheckboxPermission {
  margin: auto;
  text-align: center;
}
.rowPermissionList {
  height: 300px;
  overflow-y: auto;
}
.switchLanguage {
  margin-right: 30px;
  background-color: #26a69a;
}
.rrt-button {
  border: 0px !important;
  width: 50%;
}
.rrt-confirm-holder .rrt-confirm {
  border-radius: 0px !important;
}
.forgotPasswordLogin {
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
}
.signatureCanvas {
  border: 1px solid #707070;
  box-shadow: 0px 3px 6px #00000029;
  width: 700px;
  height: 30vh;
  display: block;
  margin: auto;
}
.__containerReactModal {
  width: 100% !important;
}
.cardStudentList .ant-card .ant-card-head {
  border-bottom: none !important;
}
.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
  .cList {
    .ant-list-item {
      padding: 2px 0px 2px 15px;
    }
  }
  .modalStyleContainer {
    width: 95vw !important;
    min-width: 95vw !important;
    .ant-card-head {
      padding: 0;
    }
    .ant-card-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .headerModal {
      padding-left: 10px;
    }
    .contentModal {
      padding: 10;
      .ant-card-body {
        padding: 15px;
      }
    }
  }
  .inputSearchSchoolName {
    width: 100%;
  }
  .containerContent {
    padding: 10px;
  }
  .formSearchClassName {
    margin-bottom: 5px;
    margin-right: 0px;
    width: 100%;
  }
  .layoutContainerComponent {
    .ant-card-body {
      padding: 15px;
    }
  }
  .formItemBtnLogin {
    margin: 0;
  }
  .rowCardSchoolList {
    margin-top: 30px;
  }
  .colLoginLeft {
    padding: 20px;
  }
  .divFormLogin {
    width: 100%;
  }
  .titleLogin {
    margin-top: 6vh;
  }
  .signatureCanvas {
    width: 350px;
    height: 30vh;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 577px) and (max-width: 767px) {
  .formSearchClassName {
    margin-bottom: 5px;
  }
  .rowCardSchoolList {
    margin-top: 30px;
  }
  .menuHeader {
    width: 100%;
  }
  .signatureCanvas {
    width: 500px;
    height: 30vh;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1599px) {
}
